<template>
  <v-card>
    <v-card-title>
      <div class="d-flex flex-row mb-0 above-table">
        <v-text-field
          v-show="searchBySiteId"
          v-model="searchSiteId"
          append-icon="mdi-magnify"
          label="Recherche par site ID"
          class="search-by-site-id mr-6"
          single-line
          hide-details
        ></v-text-field>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche (par : Nom, Domaine, Identifiant, Trigramme ou Ga View ID)"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-switch
          class="filter-with-ga-id mr-2"
          v-model="onlyWithGaId"
          label="Seulement ceux avec un ID GA"
          outlined
          dense
          @change="debouncedUpdateData"
        ></v-switch>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :options.sync="options"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :sort-by="'updated'"
        :sort-desc="true"
        :server-items-length="total"
        :loading="loading"
        class="elevation-1"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.actions="{ item }">
          <a target="_blank">
            <v-btn
              :to="`/admin/edit-site/${item.id}`"
              class="mx-2"
              elevation="0"
              fab
              small
            >
              <v-icon color="primary">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              elevation="0"
              fab
              small
              @click.native="
                dialogModel.dialog = !dialogModel.dialog;
                dialogModel.id = item.id;
                dialogModel.siteName = item.name;
              "
            >
              <v-icon color="secondary">
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </a>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialogModel.dialog" width="70vw">
      <del-confirmation
        :dialogOpen="dialogModel.dialog"
        :id="dialogModel.id"
        :name="dialogModel.siteName"
        @openDialog="openDialog"
      ></del-confirmation>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "@axios";
import { Konami, KonamiManager } from "@/utils/konami";
import DelConfirmation from "@/components/common/modals/SiteDelConfirmation";
import { mdiPencil, mdiTrashCan } from "@mdi/js";
import debounce from "lodash/debounce";

export default {
  name: "SitesTable",
  components: { DelConfirmation },
  data() {
    return {
      siteAreaList: [],
      siteBrandList: [],
      search: "",
      searchBySiteId: false,
      searchSiteId: "",
      numberOfPages: 0,
      items: [],
      loading: true,
      total: 0,
      onlyWithGaId: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      dialogModel: {
        dialog: false,
        id: -1,
        siteName: "",
      },
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nom",
          align: "start",
          value: "name",
        },
        {
          text: "Domaine",
          value: "domain",
        },
        {
          text: "Identifiant",
          value: "code",
        },
        {
          text: "Trigramme",
          value: "shortname",
        },
        {
          text: "GA PropertyID",
          value: "ga_view_id",
        },
        // {
        //   text: "is GA4",
        //   value: "is_ga4",
        // },
        {
          text: "Wys ID",
          value: "wyzistat_site_id",
        },
        // {
        //   text: "Zone",
        //   value: "area",
        // },
        // {
        //   text: "Marque",
        //   value: "brand",
        // },
        {
          value: "actions",
        },
      ],
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
      konamiManager: null,
    };
  },
  async created() {
    this.siteAreaList = await this.$store.dispatch("common/getSiteAreaList");
    this.siteBrandList = await this.$store.dispatch("common/getSiteBrandList");
    this.konamiManager = new KonamiManager(this, [
      new Konami([68, 69, 86], this.showSearchById), // d e v
    ]);
  },
  methods: {
    debouncedUpdateData: debounce(async function () {
      await this.updateData();
    }, 500),
    formatResult(items) {
      return items.map((item) => {
        const area = this.siteAreaList.find(
          (siteArea) => siteArea.id === item.area_id
        );
        const brand = this.siteBrandList.find(
          (siteBrand) => siteBrand.id === item.brand_id
        );

        return {
          ...item,
          area: area?.name ?? "",
          brand: brand?.name ?? "",
        };
      });
    },
    async updateData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        page: page - 1,
        limit: itemsPerPage,
        only_with_ga_id: this.onlyWithGaId,
        ...(this.search ? { search: this.search } : {}),
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      };
      if (this.searchSiteId.trim() != "") {
        queryParams["site_id"] = this.searchSiteId;
      }

      const { data } = await axios.get("/sites/", {
        params: queryParams,
      });
      this.loading = false;
      this.items = this.formatResult(data.items);
      this.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
    truncate(input) {
      return input.length > 5 ? `${input.substring(0, 30)}...` : input;
    },
    openDialog(value) {
      this.dialogModel.dialog = value;
    },
    showSearchById() {
      this.searchBySiteId = true;
    },
  },
  watch: {
    options() {
      this.debouncedUpdateData();
    },
    search() {
      this.options.page = 1;
      this.debouncedUpdateData();
    },
    searchSiteId() {
      this.options.page = 1;
      this.debouncedUpdateData();
    },
    "$store.state.common.siteList": function () {
      this.debouncedUpdateData();
    },
    siteAreaList() {
      this.debouncedUpdateData();
    },
    siteBrandList() {
      this.debouncedUpdateData();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-with-ga-id {
  max-width: 300px;
}
.above-table {
  width: 100%;
}
.search-by-site-id {
  width: 100px;
}
</style>
